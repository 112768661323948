// We load Sentry dynamically in the index.html, using Sentry loader,
// see https://docs.sentry.io/platforms/javascript/install/loader/
//
// This module performs extra Sentry initializtion on application load.
// The application is expected to call `sentryInit()` in the entry point.

import { IS_LOCAL_BACKEND, IS_DEVELOP, APP_ENV, RELEASE } from '@/init/settings'
import {
  SentrySDK,
  setSentryInstance,
  skipSentryEvent,
  onSkipSentryEventError,
} from '@/services/sentry'
import { assertNotNull } from '@/helpers/typing'
import { storage } from '@/helpers/storage'

// Get 1.5% of performance events on production.
let tracesSampleRate = 0.015
if (IS_DEVELOP) {
  // Get all performance events on develop.
  tracesSampleRate = 1.0
}
if (IS_LOCAL_BACKEND) {
  tracesSampleRate = 0
}

export function sentryConfig(): any {
  return {
    environment: APP_ENV,
    release: RELEASE,
    // Trace only part of all requests, we can increase or decrease this
    // depending on Sentry transactions usage.
    tracesSampleRate: tracesSampleRate,
    replaysSessionSampleRate: tracesSampleRate,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // See https://docs.sentry.io/platforms/javascript/#decluttering-sentry
    // https://docs.sentry.io/accounts/quotas/manage-event-stream-guide/
    // ignoreErrors: Instruct the SDK to never send an error to Sentry if
    // it matches any of the listed error messages. If no message is available,
    // the SDK will try to compare against an underlying exception type and value.
    ignoreErrors: ['UET is not defined', 'static.hotjar.com/c/hotjar'],
    // A list of strings or regex patterns that match error URLs which should
    // be blocked from sending events
    denyUrls: [
      'addthis_widget.js',
      'chrome-extension://',
      'file:///',
      'gtm.js',
      // Facebook flakiness
      /clarity\.js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /connect\.facebook\.net\/en_US\/sdk\.js/i,
      // Pinterest
      /s\.pinimg\.com\/ct/i,
      /ct\/lib\/main/i,
      // Google tag manager
      /www\.googletagmanager\.com\//i,
      // Profitwell
      /profitwell\.com\//i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Safari extensions
      /^safari-web-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    beforeSend(event: any, hint: any): any {
      try {
        if (skipSentryEvent(event, hint)) {
          return null // Returning null does not send the event
        }
      } catch (error) {
        onSkipSentryEventError(error)
      }
      return event
    },
  }
}

function mockFunction(this: any): any {
  console.error('Unable to lazy-load Sentry. Ad blocker?')
  return this
}

const sentryMock = {
  init: mockFunction,
  onLoad: mockFunction,
  configureScope: mockFunction,
  withScope: mockFunction,
  captureException: mockFunction,
  captureMessage: mockFunction,
  addBreadcrumb: mockFunction,
  setTag: mockFunction,
  setExtra: mockFunction,
  setContext: mockFunction,
  setFingerprint: mockFunction,
  setUser: mockFunction,
  getCurrentScope: mockFunction,
} as any

function _initSentry(): void {
  _console_log('Initializing...')

  const Sentry = assertNotNull(window.Sentry)
  Sentry.init(sentryConfig())
  setSentryInstance(Sentry)

  // We read user data from storage directly, without auth helper
  // to avoid circular dependency.
  const data = storage.getItem('user')
  if (data) {
    const user = JSON.parse(data)
    Sentry.setUser(user)
  }
}

export function sentryInit(): void {
  if ((window as any)['__PRERENDER_INJECTED']) {
    // Disable Sentry during prerendering (sentry script is not loaded).
    // @ts-ignore
    window.Sentry = {}
  } else if (!window.Sentry) {
    // Sentry is not loaded.
    _console_log('Not loaded, using mock.')
    setSentryInstance(sentryMock as SentrySDK)
  } else {
    if (!window.Sentry.SDK_VERSION) {
      // The initialization will be performed in the window.sentryOnLoad
      // function below.
      _console_log('Not fully loaded yet, using limited object.')
    } else {
      // In this case the window.sentrtOnLoad function is not invoked,
      // so we need to initialize Sentry here.
      _console_log('Already fully loaded, initializing...')
      _initSentry()
    }
    setSentryInstance(window.Sentry)
  }

  // Initialize sentry after loading, this is used by Sentry loader.
  // https://docs.sentry.io/platforms/javascript/install/loader/#custom-configuration
  window.sentryOnLoad = function (): void {
    _console_log('Run sentryOnLoad')
    _initSentry()
  }
}

export function _console_log(...data: any[]): void {
  if (window.__UNIT_TESTING) {
    return
  }
  console.log('Sentry:', ...data)
}
