import { IS_LOCAL_BACKEND, APP_VERSION } from '@/init/settings'
import { AxiosError } from 'axios'

import * as sentrySDK from '@sentry/browser'
import { replaceUUID } from '@/helpers/text'

export type SentrySDK = typeof sentrySDK

// The Sentry instance.
export let Sentry: SentrySDK

// Set Sentry service object when initializing it (npm or lazy-load).
export function setSentryInstance(value: SentrySDK): SentrySDK {
  Sentry = value
  return Sentry
}

export function skipSentryEvent(event: any, hint: any): boolean {
  const hasError =
    event.exception &&
    hint.originalException &&
    typeof hint.originalException !== 'string'

  if (!event.contexts) {
    event.contexts = {}
  }
  event.contexts['SF: APP INFO'] = {
    AppVersion: APP_VERSION,
    HasError: hasError,
  }

  // Group HTTP errors by method, URL, status code.
  if (hasError) {
    const error = hint.originalException as AxiosError
    if (error.isAxiosError === true && error.request) {
      const errorStatusCode: any = error.request.status
      const method = error.config
        ? `${error.config.method}`.toUpperCase()
        : 'no config'
      const url = error.config ? error.config.url || '' : ''
      const isNetworkError = errorStatusCode === 0
      // Replace UUIDs in URL to group similar errors together.
      const _url = replaceUUID(url)
      if (errorStatusCode === 401) {
        // Group all 401 errors together since they can happen on any URL.
        event.fingerprint = ['auth-wrong-credentials-error']
      } else if (!isNetworkError) {
        event.fingerprint = [method, _url, errorStatusCode]
      }
    }
  }

  // Example of custom logic to ingore errors:
  // if (hasError) {
  //   const error = hint.originalException as Error
  //   // const errorText = event.exception.values[0].value || ''
  //   const errorText = error.message

  //   let errorFile = ''
  //   if (error.hasOwnProperty('fileName')) {
  //     // fileName property only available in Firefox.
  //     errorFile = (error as any).fileName
  //   }
  //   if (!errorFile && error.stack) {
  //     // Take the first item of the stack.
  //     errorFile = error.stack.split('\n')[0]
  //   }

  //   const ignoreTexts = [
  //     // We are still getting these errors
  //     'UET is not defined',
  //     'static.hotjar.com/c/hotjar'
  //   ]
  //   const ignoreFiles = [
  //     'addthis_widget.js',
  //     'chrome-extension://',
  //     'file:///',
  //     'gtm.js',
  //   ]

  //   for (let idx = 0; idx < ignoreTexts.length; idx++) {
  //     const text = ignoreTexts[idx]
  //     if (errorText.indexOf(text) !== -1) {
  //       console.log('Sentry Skip', errorText)
  //       return true
  //     }
  //   }

  //   for (let idx = 0; idx < ignoreFiles.length; idx++) {
  //     const file = ignoreFiles[idx]
  //     if (errorFile.indexOf(file) !== -1) {
  //       console.log('Sentry Skip File', errorFile, errorText)
  //       return true
  //     }
  //   }
  // }

  if (IS_LOCAL_BACKEND) {
    if ((window as any)['__UNIT_TESTING']) {
      console.error('Sentry Local in unit-tests')
      console.error(event)
      return true
    } else {
      // Always skip locally.
      console.log('Sentry Local', event)
      // Note: replace with 'return false' to enable local reporting.
      return true
    }
  }

  return false
}

export function onSkipSentryEventError(error: any): void {
  Sentry.withScope((scope) => {
    scope.setContext('SF: Message', {
      message: 'Error in skipEvent handler',
    })
    Sentry.captureException(error)
  })
}
