import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Sentry } from '@/services/sentry'

type SkipFunctionType = (
  config: AxiosRequestConfig,
  errorResponse: AxiosResponse,
) => boolean

export function configSentryForAxiosError(
  error: AxiosError,
  skipFn: SkipFunctionType,
): void {
  if (error.code && error.code === 'ECONNABORTED') {
    if (Sentry.setContext) {
      Sentry.setContext('SF: Timeout', {
        'Timeout URL': error.config ? error.config.url : 'no config',
      })
    }
    return
  }

  if (!error.config) {
    // This only happened in tests, where the expect(...)
    // check for the request config values does not pass.
    const errorInfo = JSON.stringify(error)
    console.error(`Error ${errorInfo}`)
    return
  }

  const config: AxiosRequestConfig = error.config

  // Delete Authorization header.
  // If leave it as is - Senrty will make [Filtered] for all headers.
  const headers = config.headers
  if (headers) {
    delete headers['Authorization']
  }

  const errorData: any = {
    config: {
      baseURL: config.baseURL,
      url: config.url,
      method: config.method,
      data: JSON.stringify(config.data),
      headers: JSON.stringify(headers),
    },
  }
  let errorStatusCode: any = null
  let errorResponseText: string = ''

  if (error.request) {
    // Add request info to the error data.
    errorStatusCode = error.request.status
    errorResponseText = error.request.responseText
    errorData['request'] = {
      status: errorStatusCode,
      statusText: error.request.statusText,
      responseUrl: error.request.responseURL,
      responseText: errorResponseText,
    }
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // Check if we want to skip this error.
    try {
      if (skipFn) {
        if (skipFn(error.config, error.response)) {
          return
        }
      }
    } catch (e) {
      console.log('Error in _skipError', e)
    }

    /* eslint-disable no-console */
    if (window.__UNIT_TESTING !== true) {
      const errorInfo = JSON.stringify(error.response.data)
      console.log(
        `Error ${error.config.method} ${error.config.url} response ${errorInfo}`,
      )
    }
    /* eslint-enable no-console */

    // Add response data to the error.
    errorStatusCode = error.response.status
    errorData['response'] = {
      data: JSON.stringify(error.response.data),
      headers: JSON.stringify(error.response.headers),
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request)
    errorData['response'] = 'No Response'
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message)
    errorStatusCode = 'Error'
    errorResponseText = ''
    errorData['request'] = 'No Request'
    errorData['response'] = 'No Response'
  }

  if (error.code) {
    errorData['code'] = error.code
  }

  const method = `${error.config.method}`.toUpperCase()
  const url = error.config.url
  const isNetworkError = errorStatusCode === 0
  if (Sentry.setContext) {
    Sentry.setContext('SF: Axios Error Info', {
      'Error Status Code': errorStatusCode,
      'Error Data': errorData,
      'Error Message (original)': error.message,
    })
  }
  if (!isNetworkError) {
    // Update error with more informative message to have better title in Sentry.
    error.message = `Request [${method}] ${url} - Error ${errorStatusCode}`
  }
}
